<template>
  <div class="about">
    <BlackBanner />
    <div class="header"></div>
    <div class="slogan">
      <p class="top">
        Our mission is to extend the life cycle of mobile devices
      </p>
      <p class="bottom">
        We are a leading provider of mobile device handling solutions for
        wireless carriers, reverse logistics providers, insurance carriers and
        more...
      </p>
    </div>
    <div class="content">
      <div class="content-device">
        <p class="top">E-Waste Reduction</p>
        <p class="bottom">
          One of the biggest contributors to e-waste from mobile devices is the
          inability to properly test ahd evaluate a devided for resale in the
          secondary market, often resulting in a device being harvested for
          parts or worse, ending up in a landfill.
        </p>
      </div>
    </div>
    <div class="env">
      <div class="image">
        <img src="@/assets/images/about/about2.png" />
      </div>
    </div>
    <div class="team">
      <div class="c-left">
        <img src="@/assets/images/about/team1.png" />
      </div>
      <div class="c-right">
        <div class="right1">
          <p>At K-Check,</p>
          <p>we value collaboration,</p>
          <p>sharing, and mutual success</p>
        </div>
        <div class="right2">
          <p>
            Our top priority is to meet our customers' needs <br />
            by delivering valuable solutions early and consistently. <br />
            Our team is here to help you get started and to support <br />
            you here。
          </p>
        </div>
        <div class="img"><img src="@/assets/images/about/team2.png" /></div>
      </div>
    </div>
    <div class="message">
      <p>
        Whether you want to test, clean or grade devices, K-CHECK is your
        partner today and tomorrow!
      </p>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import BlackBanner from "@/components/common/BlackBanner.vue";

export default defineComponent({
  name: "About",
  components: {
    BlackBanner,
  },
});
</script>

<style lang="less" scoped>
.about {
  width: 100%;
  .header {
    height: 699px;
    background: url("~@/assets/images/about/about1.png");
    background-size: cover;
  }
  .slogan {
    width: 979px;
    height: 648px;
    padding-left: 191px;
    background: #2a3e71;
    border-radius: 11px;
    margin-left: 138px;
    margin-top: -198px;
    position: relative;
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: center;
    .top {
      width: 559px;
      height: 114px;
      font-size: 48px;
      font-family: Abhaya Libre SemiBold;
      font-weight: 400;
      color: #ffffff;
      text-align: left;
    }
    .bottom {
      width: 609px;
      height: 72px;
      font-size: 24px;
      font-family: Abhaya Libre SemiBold;
      font-weight: 400;
      color: #ffffff;
      line-height: 24px;
      text-align: left;
    }
  }
  .content {
    background-image: url("~@/assets/images/about/device.png");
    background-size: cover;
    height: 444px;
    margin-top: 90px;
    margin: 90px 80px 0;
    .content-device {
      float: right;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 723px;
      height: 100%;
      margin-right: 200px;
      font-weight: 400;
      text-align: LEFT;
      color: #040404;
      &:first-of-type {
        p {
          margin-bottom: 0px;
        }
      }
      .top {
        font-family: Abhaya Libre SemiBold;
        font-size: 48px;
      }
      .bottom {
        line-height: 28px;
      }
    }
  }
  .env {
    margin-top: 54px;
    .image {
      height: 494px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .team {
    margin-top: 109px;
    display: flex;
    .c-left {
      flex-basis: 48%;
      padding-right: 16px;
      display: flex;
      justify-content: flex-end;
      img {
        display: inline-block;
        height: 100%;
        padding-right: 20%;
      }
    }
    .c-right {
      flex-basis: 52%;
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      p {
        margin: 0px;
      }
      .right1 {
        width: 631px;
        font-size: 36px;
        font-family: Abhaya Libre ExtraBold, Abhaya Libre ExtraBold-Regular;
        font-weight: 400;
        text-align: left;
        color: #000000;
        line-height: 38px;
      }
      .right2 {
        width: 631px;
        // height: 136px;
        font-size: 24px;
        font-weight: 400;
        text-align: LEFT;
        color: #000000;
        line-height: 32px;
        padding-top: 16px;
        padding-bottom: 36px;
      }
      .img {
        font-size: 0px;
      }
    }
  }
  .message {
    height: 126px;
    background: #2a3e71;
    padding-top: 24px;
    line-height: 50px;
    margin-top: 40px;
    p {
      margin: 0 auto;
      width: 730px;
      height: 60px;
      font-size: 36px;
      font-family: Abhaya Libre ExtraBold;
      font-weight: 400;
      text-align: CENTER;
      color: #ffffff;
    }
  }
}
</style>
